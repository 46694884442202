<!-- eslint-disable max-len -->
<!-- eslint-disable vue/max-len -->
<template>
  <div class="">
    <svg v-if="wType === 9" class="icon-rain-low" viewBox="0 0 33 33">
      <path style="fill:#B8B2FF;" d="M28.433,8.422c0.012-0.107,0.018-0.215,0.018-0.324c0-2.258-2.557-4.088-5.712-4.088 c-1.448,0-2.77,0.386-3.777,1.022c-0.213-0.017-0.429-0.026-0.648-0.026c-2.767,0-5.074,1.408-5.599,3.277 c-2.997,0.126-5.373,1.904-5.373,4.08c0,2.258,2.557,4.088,5.712,4.088c1.21,0,2.333-0.27,3.256-0.729 c0.924,0.46,2.046,0.729,3.256,0.729c1.399,0,2.681-0.36,3.674-0.958c0.993,0.598,2.275,0.958,3.674,0.958 c3.154,0,5.712-1.83,5.712-4.088C32.626,10.482,30.85,8.898,28.433,8.422z"/>
      <path style="fill:#000D6E;" d="M29.824,19.301c-0.091-0.186-0.28-0.303-0.487-0.303h-5.083l4.637-6.206 c0.18-0.24,0.13-0.581-0.11-0.76c-0.24-0.18-0.581-0.13-0.76,0.11l-5.286,7.074c-0.123,0.165-0.143,0.385-0.051,0.568 c0.092,0.184,0.28,0.3,0.486,0.3h5.063l-4.496,5.834l-0.319-3.45c-0.02-0.216-0.211-0.374-0.427-0.354l-0.147,0.014 c-0.215,0.02-0.374,0.211-0.354,0.426l0.48,5.176l5.128-0.855c0.213-0.036,0.358-0.237,0.322-0.451l-0.024-0.146 c-0.035-0.214-0.237-0.358-0.451-0.323l-3.291,0.549l5.113-6.634C29.893,19.709,29.915,19.487,29.824,19.301z"/>
      <g>
        <path style="fill:#6C2273;" d="M9.712,20.099c0.157,0,0.311-0.073,0.409-0.211l1.516-2.14c0.159-0.226,0.106-0.537-0.119-0.697 c-0.225-0.157-0.537-0.106-0.697,0.119l-1.516,2.14c-0.159,0.226-0.106,0.537,0.119,0.697 C9.512,20.068,9.612,20.099,9.712,20.099z"/>
        <path style="fill:#6C2273;" d="M9.067,22.262c0.318-0.452,0.209-1.076-0.242-1.393c-0.452-0.318-1.076-0.209-1.393,0.242 c-0.318,0.452-0.209,1.076,0.242,1.393S8.749,22.713,9.067,22.262z"/>
        <path style="fill:#6C2273;" d="M14.801,18.963c-0.226-0.158-0.538-0.106-0.697,0.119l-3.706,5.228 c-0.16,0.226-0.107,0.537,0.119,0.697c0.088,0.062,0.188,0.092,0.289,0.092c0.157,0,0.311-0.073,0.408-0.211l3.706-5.228 C15.079,19.435,15.026,19.123,14.801,18.963z"/>
        <path style="fill:#6C2273;" d="M8.365,25.94c-0.318,0.452-0.209,1.075,0.242,1.393c0.452,0.318,1.075,0.209,1.393-0.242 s0.209-1.076-0.242-1.393S8.683,25.488,8.365,25.94z"/>
        <path style="fill:#6C2273;" d="M16.007,24.53c0.157,0,0.311-0.073,0.408-0.211l3.472-4.897c0.16-0.226,0.107-0.537-0.119-0.697 c-0.226-0.157-0.538-0.106-0.697,0.119L15.6,23.741c-0.16,0.226-0.107,0.537,0.119,0.697C15.807,24.5,15.907,24.53,16.007,24.53z "/>
        <path style="fill:#6C2273;" d="M15.347,26.683c0.318-0.452,0.209-1.076-0.242-1.393s-1.076-0.209-1.393,0.242 s-0.209,1.076,0.242,1.393C14.406,27.243,15.029,27.134,15.347,26.683z"/>
        <path style="fill:#6C2273;" d="M20.42,24.08c-0.226-0.158-0.538-0.106-0.697,0.119l-3.046,4.297 c-0.16,0.226-0.107,0.537,0.119,0.697c0.088,0.062,0.188,0.092,0.289,0.092c0.157,0,0.311-0.073,0.408-0.211l3.046-4.297 C20.699,24.552,20.646,24.24,20.42,24.08z"/>
        <path style="fill:#6C2273;" d="M14.644,30.126c-0.318,0.452-0.209,1.075,0.242,1.393c0.452,0.318,1.075,0.209,1.393-0.242 c0.318-0.452,0.209-1.075-0.242-1.393S14.962,29.675,14.644,30.126z"/>
      </g>
    </svg>
    <svg v-if="wType === 10" class="icon-rain-low" viewBox="0 0 24 24">
      <path d="M17.49 6H18a6 6 0 0 1 2.4 11.5 1 1 0 1 1-.8-1.83A4 4 0 0 0 18 8h-1.25a1 1 0 0 1-1-.75 7 7 0 1 0-11.12 7.22A1 1 0 0 1 3.38 16 9 9 0 1 1 17.49 6zM11 19a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm4-2a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm-8 0a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0z"></path>
    </svg>
    <svg v-else-if="wType === 11" class="icon-rain-middle" viewBox="0 0 24 24">
      <path d="M17.49 6H18a6 6 0 0 1 2.4 11.5 1 1 0 1 1-.8-1.83A4 4 0 0 0 18 8h-1.25a1 1 0 0 1-1-.75 7 7 0 1 0-11.12 7.22A1 1 0 0 1 3.38 16 9 9 0 1 1 17.49 6zM11 15a1 1 0 1 1 2 0v2a1 1 0 0 1-2 0zm0 6a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0zm4-8a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm0 6a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zm-8-6a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0zm0 6a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0z"></path>
    </svg>
    <svg v-else-if="wType === 12" class="icon-rain-high" viewBox="0 0 40 40">
      <path d="M27.34 31.67a1.33 1.33 0 0 1 2.67 0v2.67a1.33 1.33 0 0 1-2.67 0zM14 33a1.33 1.33 0 1 1 2.67 0v2.67a1.33 1.33 0 1 1-2.67 0zm5.55 1.93l4-5.93H18a1.33 1.33 0 0 1-1.11-2.07l5.33-8a1.33 1.33 0 1 1 2.22 1.48l-4 5.93H26a1.33 1.33 0 0 1 1.11 2.07l-5.33 8a1.33 1.33 0 1 1-2.22-1.48zM18 5a12 12 0 0 1 11.31 8H30a8 8 0 0 1 1.6 15.84 1.33 1.33 0 0 1-.53-2.61A5.33 5.33 0 0 0 30 15.67h-1.68a1.33 1.33 0 0 1-1.29-1 9.33 9.33 0 1 0-13.56 10.5 1.33 1.33 0 0 1-1.29 2.33A12 12 0 0 1 18 5z"></path>
    </svg>
    <svg v-else-if="wType === 20" class="icon-temp-low" viewBox="0 0 24 24">
      <path d="M13.168 3.79c0-1.302-.578-1.302-.824-1.302-.25 0-.828 0-.828 1.301V5h1.652zm-5.3 11.847A5.274 5.274 0 0 0 7 18.523c0 2.954 2.414 5.344 5.387 5.344.379 0 .742-.039 1.097-.11 2.45-.503 4.293-2.655 4.293-5.234 0-1.96-1.07-3.675-2.66-4.601V2.777c0-1.32-.933-2.425-2.18-2.707a2.816 2.816 0 0 0-1.96.266 2.775 2.775 0 0 0-1.461 2.441v11.23a5.42 5.42 0 0 0-1.649 1.63zm4.476-13.594c.969 0 1.273.781 1.273 1.746v11.793c1.332.34 2.04 1.484 2.133 2.625.012.086.02.172.02.262 0 1.851-1.516 3.355-3.383 3.355s-3.38-1.504-3.38-3.355c0-.09.009-.176.02-.266.051-.625.274-1.18.633-1.621.356-.437.84-.773 1.41-.945V3.789c0-.965.301-1.746 1.274-1.746zm1.738 18.574c.691-.742 1.012-1.656.922-2.562-.063-.617-1.313-.621-1.254 0 .078.758-.172 1.457-.75 2.078-.469.504.617.984 1.082.484zm0 0"></path>
    </svg>
    <svg v-else-if="wType === 21" class="icon-temp-middle" viewBox="0 0 24 24">
      <path d="M14.08 20.62a3.28 3.28 0 0 0 .92-2.57c-.06-.61-1.31-.62-1.25 0a2.59 2.59 0 0 1-.75 2.08c-.47.51.62.99 1.08.49zM12.34 2c1 0 1.27.78 1.27 1.74v11.84a2.87 2.87 0 0 1 2.13 2.62 2 2 0 0 1 0 .27 3.38 3.38 0 0 1-6.76 0 2 2 0 0 1 0-.27 2.92 2.92 0 0 1 .63-1.62 2.87 2.87 0 0 1 1.41-.95V3.79c.05-.96.35-1.79 1.32-1.79zM7.87 15.64A5.26 5.26 0 0 0 7 18.52a5.37 5.37 0 0 0 5.39 5.34 5.44 5.44 0 0 0 1.1-.11 5.33 5.33 0 0 0 1.63-9.84V2.78A2.78 2.78 0 0 0 12.94.07a2.81 2.81 0 0 0-2 .26 2.77 2.77 0 0 0-1.43 2.45V14a5.4 5.4 0 0 0-1.64 1.64zm5.3-8.52V3.79c0-1.3-.58-1.3-.83-1.3s-.83 0-.83 1.3v5.76h1.65V7.11z"></path>
    </svg>
    <svg v-else-if="wType === 22" class="icon-temp-high" viewBox="0 0 24 24">
      <path d="M13.168 3.79c0-1.302-.578-1.302-.824-1.302-.25 0-.828 0-.828 1.301v11.762h1.652zm-5.3 11.847A5.274 5.274 0 0 0 7 18.523c0 2.954 2.414 5.344 5.387 5.344.379 0 .742-.039 1.097-.11 2.45-.503 4.293-2.655 4.293-5.234 0-1.96-1.07-3.675-2.66-4.601V2.777c0-1.32-.933-2.425-2.18-2.707a2.816 2.816 0 0 0-1.96.266 2.775 2.775 0 0 0-1.461 2.441v11.23a5.42 5.42 0 0 0-1.649 1.63zm4.476-13.594c.969 0 1.273.781 1.273 1.746v11.793c1.332.34 2.04 1.484 2.133 2.625.012.086.02.172.02.262 0 1.851-1.516 3.355-3.383 3.355s-3.38-1.504-3.38-3.355c0-.09.009-.176.02-.266.051-.625.274-1.18.633-1.621.356-.437.84-.773 1.41-.945V3.789c0-.965.301-1.746 1.274-1.746zm1.738 18.574c.691-.742 1.012-1.656.922-2.562-.063-.617-1.313-.621-1.254 0 .078.758-.172 1.457-.75 2.078-.469.504.617.984 1.082.484zm0 0"></path>
    </svg>
    <svg v-else-if="wType === 30" id="icon-snow-low" viewBox="0 0 24 24">
      <path d="M9.508.016c3.644.199 6.777 2.53 7.976 5.855h.524c2.836.004 5.285 1.953 5.863 4.672.582 2.723-.863 5.465-3.465 6.582a1.013 1.013 0 0 1-1.316-.504.968.968 0 0 1 .515-1.289c1.73-.746 2.696-2.574 2.31-4.387-.388-1.812-2.017-3.113-3.911-3.117h-1.258a.994.994 0 0 1-.969-.734c-.761-2.883-3.343-4.957-6.382-5.125C6.352 1.8 3.55 3.579 2.46 6.363c-1.086 2.782-.211 5.938 2.168 7.797.434.34.504.953.16 1.375a1.018 1.018 0 0 1-1.406.156C.324 13.297-.805 9.242.593 5.664 1.994 2.082 5.599-.203 9.509.016zm7.004 22.941c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm-4 5.309c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm-4 4.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm0 0"></path>
    </svg>
    <svg v-else-if="wType === 31" id="icon-snow-middle" viewBox="0 0 24 24">
      <path d="M9.508.016c3.644.199 6.777 2.53 7.976 5.855h.524c2.836.004 5.285 1.953 5.863 4.672.582 2.723-.863 5.465-3.465 6.582a1.013 1.013 0 0 1-1.316-.504.968.968 0 0 1 .515-1.289c1.73-.746 2.696-2.574 2.31-4.387-.388-1.812-2.017-3.113-3.911-3.117h-1.258a.994.994 0 0 1-.969-.734c-.761-2.883-3.343-4.957-6.382-5.125C6.352 1.8 3.55 3.579 2.46 6.363c-1.086 2.782-.211 5.938 2.168 7.797.434.34.504.953.16 1.375a1.018 1.018 0 0 1-1.406.156C.324 13.297-.805 9.242.593 5.664 1.994 2.082 5.599-.203 9.509.016zm7.004 22.941c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm-4 5.309c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm-4 4.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm0 0"></path>
    </svg>
    <svg v-else-if="wType === 32" id="icon-snow-high" viewBox="0 0 24 24">
      <path d="M9.508.016c3.644.199 6.777 2.53 7.976 5.855h.524c2.836.004 5.285 1.953 5.863 4.672.582 2.723-.863 5.465-3.465 6.582a1.013 1.013 0 0 1-1.316-.504.968.968 0 0 1 .515-1.289c1.73-.746 2.696-2.574 2.31-4.387-.388-1.812-2.017-3.113-3.911-3.117h-1.258a.994.994 0 0 1-.969-.734c-.761-2.883-3.343-4.957-6.382-5.125C6.352 1.8 3.55 3.579 2.46 6.363c-1.086 2.782-.211 5.938 2.168 7.797.434.34.504.953.16 1.375a1.018 1.018 0 0 1-1.406.156C.324 13.297-.805 9.242.593 5.664 1.994 2.082 5.599-.203 9.509.016zm7.004 22.941c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm-4 5.309c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm-4 4.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.5c-.555 0-1-.437-1-.977 0-.543.445-.98 1-.98.55 0 1 .438 1 .98a.99.99 0 0 1-1 .977zm0-3.309c-.555 0-1-.437-1-.98 0-.54.445-.977 1-.977.55 0 1 .438 1 .977a.99.99 0 0 1-1 .98zm0 0"></path>
    </svg>
  </div>
</template>

<script>
import configEnv from '@/enums/configEnv';

export default {
  props: {
    type: String,
    intensity: String,
  },
  computed: {
    wType() {
      if (this.type === 'rain' && configEnv.baloise_strings) {
        return 9;
      }

      let type = 10;
      switch (this.type) {
      case 'rain':
        type = 10;
        break;
      case 'temperature':
        type = 20;
        break;
      case 'snow':
        type = 30;
        break;
      default:
        break;
      }
      switch (this.intensity) {
      case 'low':
        type += 0;
        break;
      case 'middle':
        type += 1;
        break;
      case 'high':
        type += 2;
        break;
      default:
        break;
      }
      return type;
    },
  },
};
</script>
